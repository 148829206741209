import * as React from 'react';
import { graphql, HeadProps, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/layout';
import Seo from '../components/seo';
import { Title, TitleHighlighted } from '../styles/typography';
import { ImageContactBuilding, ImageMap } from '../assets/images';
import { ImageLogoBlack } from '../assets/images/logos';
import Hero from '../components/sections/hero';
import { down } from 'styled-breakpoints';
import { ButtonHover } from '../styles/layout';
import { ImageSocialContact } from '../assets/images/social';

export interface Props {
	location: Location;
}

const InfoSection = styled.section`
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-top: 80px;
	margin-bottom: -237px; // to overflow ImageSection
	z-index: 1;

	+ section {
		${down('sm')} {
			height: 200px;
			padding-top: 170px;
		}
	}
`;

const InfoContainer = styled.div`
	background-color: rgb(255, 255, 255);
	width: 285px;
	padding: 51px;
	margin: 0 10px 20px 10px; // must be same as in MapContainer to break nicely on large breakpoints
	border-radius: 12px;
	box-shadow: 0px 8px 60px rgba(0, 0, 0, 0.1);

	img {
		margin-bottom: 40px;
	}

	ul {
		list-style: none;
		margin-bottom: 32px;

		li:last-child {
			margin-top: 8px;
			font-weight: 700;
		}
	}

	p {
		margin-top: 44px;
		color: rgb(142, 146, 155);
	}

	${down('xxl')} {
		max-width: 691px;
		width: 100%;
	}
`;

const Button = styled(ButtonHover)`
	font-size: 14px;
	font-weight: 700;
	color: rgb(46, 87, 160);
	background-color: rgba(46, 87, 160, 0.1);
	padding: 16px 12px;
	border-radius: 8px;

	img {
		display: none;
		width: 18px;
		height: 18px;
	}

	.icon {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		margin-right: 8px;
		background-color: rgb(46, 87, 160);
		color: rgb(255, 255, 255);
		border-radius: 100%;
		padding: 4.5px 6.73px;
		font-size: 9px;
	}
`;

const MapContainer = styled.div`
	max-width: 761px;
	width: 100%;
	margin: 0 10px 20px 10px;
	border: 16px solid rgb(255, 255, 255);
	border-radius: 12px;
	box-shadow: 0px 8px 60px rgba(0, 0, 0, 0.1);
`;

export const ContactPage = ({ location }: Props) => {
	const { site } = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					fbSiteUrl
					email
				}
			}
		}
	`);

	const { email, fbSiteUrl } = site.siteMetadata;

	return (
		<Layout location={location}>
			<Title>
				<TitleHighlighted>Stopite v stik z nami</TitleHighlighted>
			</Title>
			<InfoSection>
				<InfoContainer>
					<img src={ImageLogoBlack} alt="Logo" />
					<ul>
						<li>Tehnološki Park Ptuj</li>
						<li>Vičava 1</li>
						<li>2250 Ptuj</li>
						<li>
							<a href={`mailto:${email}`}>{email}</a>
						</li>
					</ul>
					<Button href={fbSiteUrl} target="_blank">
						<span className="icon icon-fb"></span>Tehnološki park Ptuj
					</Button>
					<p>Za voden ogled nas kontaktirajte s sporočilom na e-mail.</p>
				</InfoContainer>
				<MapContainer>
					<ImageMap />
				</MapContainer>
			</InfoSection>
			<Hero image={<ImageContactBuilding />} height={822} />
		</Layout>
	);
};

export const Head = (props: HeadProps) => {
	return (
		<Seo
			title={'Kontakt'}
			description={'Lokacija: Tehnološki park Ptuj, Vičava 1, 2250 Ptuj, Slovenija | Kontakt: info@tpp.si'}
			image={ImageSocialContact}
			locationPathName={props.location.pathname}
		/>
	);
};

export default ContactPage;
